import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import {
  Section,
  Container,
  Title,
  Column,
  Content,
  Button,
  Icon,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import CTA from "../components/cta"

const IndexPage = ({ data }) => {
  const page = data.site.siteMetadata
  return (
    <Layout>
      <SEO title="Home" />
      <Hero siteTitle={page.title} description={page.description} />
       {/* Intro section  */}
      {/* <Section>
        <Container>
          <Column isSize="2/3">
            <Title>Services</Title>
            <Content>
              <p>
              </p>
              List of features
              <ul id="list-unstyled">
                <li>
                  <Icon
                    className="fab fa-sass fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Sass Ready
                </li>
                <li>
                  <Icon
                    className="fas fa-icons fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Font Awesome Icons
                </li>
                <li>
                  <Icon
                    className="fas fa-tachometer-alt fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Progressive Web App
                </li>
                <li>
                  <Icon
                    className="fas fa-caret-square-down fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Sticky Footer
                </li>
              </ul>
            </Content>
          </Column>
        </Container>
      </Section> */}
      <CTA title="Ready to simplify your security?" subtitle="Signup for free. Get started in minutes."/>
    </Layout>
  )
}

IndexPage.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }),
}
export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
