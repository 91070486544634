import React from "react"
import PropTypes from "prop-types"

import {
  Title,
  Subtitle,
  Section,
  Container,
  Columns,
  Column,
  Button,
} from "bloomer"
import { Link } from "gatsby"

const CTA = (props) => (
  <Section className="call-to-action">
    <Container hasTextAlign="centered">
          <Title hasTextColor="white">{props.title}</Title>
          <Subtitle hasTextColor="white">{props.subtitle}</Subtitle>
          <Container className="action">
          <Link to="/">
            <Button isSize="medium" isColor="light" hasTextColor="dark">
              Sign up
            </Button>
          </Link>
          <Link to="/">
            <Button isSize="medium" isColor="light" hasTextColor="dark">
              Schedule a Demo
            </Button>
          </Link>
          </Container>
    </Container>
  </Section>
)

export default CTA


CTA.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}
