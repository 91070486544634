import React, { Component } from "react"
import {
  Hero,
  HeroBody,
  Container,
  Title,
  Subtitle,
  Button,
  Columns,
  Column,
  Icon,
} from "bloomer"
import HackerMindset from "../assets/hacker-mindset.svg"

export default class hero extends Component {
  render() {
    return (
      <Hero className="hero-gradient" isSize="medium" textalign="left">
        <HeroBody>
          <Container>
            <Columns>
              <Column isSize="1/2">
                {/* Headline */}
                {/* <Title>{this.props.description}</Title> */}
                <Title>
                  Simplify Your{" "}
                  <span className="has-text-primary">Security.</span>
                </Title>
                <Subtitle>Monitoring and Protection made easy.</Subtitle>
                {/* Call to action buttons */}
                <Button
                  isColor="primary"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-rounded"
                  id="hero-btn">
                  {/* Call to action button icon */}
                  <span>Free Trial</span>
                </Button>

                <Button
                  isColor="primary"
                  isOutlined
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-rounded"
                  id="btn-spaced">
                  {/* Call to action button icon */}
                  <span>Schedule a Demo</span>
                </Button>
              </Column>
              {/* Hero Image */}
              <Column isSize="1/2">
                <HackerMindset className="big-img" />
              </Column>
            </Columns>
          </Container>
        </HeroBody>
      </Hero>
    )
  }
}
